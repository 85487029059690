import {
  BASE_URL,
  BASE_URL_USER,
  Instance,
} from "../commonservice/commonservice";
import { ENDPOINTS_API } from "./apiConstants";
import { callAPI } from "./callApi";
// import Cookies from "js-cookie";

let token = localStorage.getItem("token");

export const getUsersList = async (currentPage, search) => {
  const obj = {};
  try {
    console.log("call2");
    const result = await callAPI(
      `${
        BASE_URL + ENDPOINTS_API.GETUSERDETAILS
      }?page=${currentPage}&search=${search}`,
      "GET",
      {
        "content-type": "application/json",
      }
    );
    return result?.data;
  } catch (error) {
    return error;
  }
};

export const handleUserDelete = async (id, block) => {
  console.log(block, id, "action");
  try {
    const result = await Instance(
      "PUT",
      `${BASE_URL}${ENDPOINTS_API.USER_DELETE}/${id}`,
      {
        Authorization: `Bearer ${token}`,
      },
      {
        block: block == "suspend",
        type: block == "suspend" ? "block" : "unblock",
      }
    );

    return result;
  } catch (error) {
    console.log(error.response.data.message);

    return error;
  }
};

export const getAdminProfile = async () => {
  try {
    const result = await callAPI(
      BASE_URL + ENDPOINTS_API.GETADMINPROFILE,
      "GET",

      {
        "content-type": "application/json",
      }
    );
    return result?.data;
  } catch (error) {
    return error;
  }
};

export const getUserDetails = async ({ id }) => {
  const obj = { id };
  try {
    const result = await Instance(
      "POST",
      BASE_URL + ENDPOINTS_API.GETUSERBYID,
      {
        Authorization: `Bearer ${token}`,
      },
      obj
    );
    return result?.data;
  } catch (error) {
    return error;
  }
};

export const handleAddGame = async (formdata) => {
  const obj = {};
  try {
    const result = await Instance(
      "POST",
      BASE_URL + ENDPOINTS_API.ADDGAME,
      {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      formdata
    );
    return result?.data;
  } catch (error) {
    return error;
  }
};

export const getAllGame = async (currentPage, search) => {
  const obj = {};
  try {
    const result = await Instance(
      "GET",
      `${
        BASE_URL + ENDPOINTS_API.ALLGAME
      }?page=${currentPage}&search=${search}`,
      {
        Authorization: `Bearer ${token}`,
      },
      obj
    );
    return result?.data;
  } catch (error) {
    return error;
  }
};

export const handleAddGameEvent = async (getData) => {
  const obj = {};
  try {
    const result = await Instance(
      "POST",
      BASE_URL + ENDPOINTS_API.ADDGAMEEVENT,
      {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      getData
    );
    return result?.data;
  } catch (error) {
    return error;
  }
};

export const handleGameById = async ({ currentPage, id, filter }) => {
  const obj = { id };
  try {
    const result = await Instance(
      "POST",
      `${
        BASE_URL + ENDPOINTS_API.GETGAMEBYID
      }?page=${currentPage}&filter=${filter}`,
      {
        Authorization: `Bearer ${token}`,
      },
      obj
    );
    return result?.data;
  } catch (error) {
    return error;
  }
};

export const handleGameEventById = async ({ id }) => {
  const obj = { id };
  try {
    const result = await Instance(
      "POST",
      BASE_URL + ENDPOINTS_API.GETEVENTBYID,
      {
        Authorization: `Bearer ${token}`,
      },
      obj
    );
    return result?.data;
  } catch (error) {
    return error;
  }
};

export const handleGetParticipants = async ({ id, search }) => {
  const obj = {};
  try {
    const result = await Instance(
      "GET",
      `${BASE_URL + ENDPOINTS_API.GETPARTICIPANTS}${id}?search=${search}`,
      {
        Authorization: `Bearer ${token}`,
      },
      obj
    );
    return result?.data;
  } catch (error) {
    return error;
  }
};

export const handleGetParticipantById = async ({ id }) => {
  const obj = {};
  try {
    const result = await Instance(
      "GET",
      BASE_URL + ENDPOINTS_API.GETPARTICIPANTBYID + `/${id}`,
      {
        Authorization: `Bearer ${token}`,
      },
      obj
    );
    return result?.data;
  } catch (error) {
    return error;
  }
};

export const handleEditGameEvent = async (getData) => {
  const obj = {};
  try {
    const result = await Instance(
      "POST",
      BASE_URL + ENDPOINTS_API.EDITGAMEEVENT,
      {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      getData
    );
    return result?.data;
  } catch (error) {
    return error;
  }
};

export const handleAdminLogin = async ({ email, password, deviceToken }) => {
  const obj = { email, password, deviceToken };
  try {
    const result = await Instance(
      "POST",
      BASE_URL + ENDPOINTS_API.LOGIN,
      {},
      obj
    );

    return result?.data || result?.response?.data;
  } catch (error) {
    return error;
  }
};

export const handleOTPForgotPasword = async ({ email }) => {
  const obj = { email };
  try {
    const result = await Instance(
      "POST",
      BASE_URL + ENDPOINTS_API.FORGOTPASSWORD,
      {},
      obj
    );
    return result?.data;
  } catch (error) {
    return error;
  }
};

export const handleChangePassword = async ({
  email,
  otp,
  newPassword,
  confirmPassword,
}) => {
  const obj = { email, otp, newPassword, confirmPassword };

  try {
    const result = await Instance(
      "POST",
      BASE_URL + ENDPOINTS_API.CHANGEPASSWORD,
      {},
      obj
    );

    return result?.data || result?.response?.data;
  } catch (error) {
    return error;
  }
};

export const handleEditAdminProfile = async (getData) => {
  const obj = {};
  try {
    const result = await Instance(
      "PUT",
      BASE_URL + ENDPOINTS_API.EDITPROFILE,
      {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      getData
    );
    return result?.data;
  } catch (error) {
    return error;
  }
};

export const handleAdminUpdatePassword = async ({
  password,
  newPassword,
  confirmPassword,
}) => {
  const obj = { password, newPassword, confirmPassword };

  try {
    const result = await Instance(
      "PUT",
      BASE_URL + ENDPOINTS_API.UPDATEPASSWORD,
      { Authorization: `Bearer ${token}` },
      obj
    );

    return result?.data || result?.response?.data;
  } catch (error) {
    return error;
  }
};

export const handleAddLudoChallenge = async ({
  roomId,
  entryFee,
  gameType,
}) => {
  const obj = { roomId, entryFee, gameType, createdBy: "admin" };

  try {
    const result = await Instance(
      "POST",
      BASE_URL_USER + ENDPOINTS_API.CHALLENGE,
      { Authorization: `Bearer ${token}` },
      obj
    );

    return result?.data;
  } catch (error) {
    return error;
  }
};

export const handleGetLudoChallenges = async (currentPage, search, filter) => {
  const obj = {};

  try {
    const result = await Instance(
      "GET",
      `${
        BASE_URL + ENDPOINTS_API.GETALLCHALLENGES
      }?page=${currentPage}&filter=${filter}`,
      { Authorization: `Bearer ${token}` },
      obj
    );

    return result?.data;
  } catch (error) {
    return error;
  }
};

export const handleAddBanner = async (getData) => {
  const obj = {};

  try {
    const result = await Instance(
      "POST",
      BASE_URL + ENDPOINTS_API.ADDBANNER,
      {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      getData
    );
    return result?.data;
  } catch (error) {
    return error;
  }
};

export const handleGetAllBanner = async (currentPage) => {
  const obj = {};

  try {
    const result = await Instance(
      "GET",
      `${BASE_URL + ENDPOINTS_API.GETALLBANNER}?page=${currentPage}`,
      {
        Authorization: `Bearer ${token}`,
      },
      obj
    );
    return result?.data;
  } catch (error) {
    return error;
  }
};

export const handleCancellChallenge = async ({ challengeId }) => {
  const obj = {
    challengeId,
  };

  try {
    const result = await Instance(
      "POST",
      BASE_URL_USER + ENDPOINTS_API.CANCELLCHALLENGE,
      {
        Authorization: `Bearer ${token}`,
      },
      obj
    );
    return result?.data;
  } catch (error) {
    return error;
  }
};

export const handleBannerEdit = async (getData) => {
  // const obj = { getData };

  try {
    const result = await Instance(
      "PUT",
      BASE_URL + ENDPOINTS_API.EDITBANNER,
      {
        Authorization: `Bearer ${token}`,
      },
      getData
    );
    return result?.data;
  } catch (error) {
    return error;
  }
};

export const handleBannerDeleteById = async ({ id }) => {
  const obj = {};

  try {
    const result = await Instance(
      "DELETE",
      BASE_URL + ENDPOINTS_API.DELETEBANNER + `/${id}`,
      {
        Authorization: `Bearer ${token}`,
      },
      obj
    );
    return result?.data;
  } catch (error) {
    return error;
  }
};

export const handleDeleteGameById = async ({ id }) => {
  const obj = {};

  try {
    const result = await Instance(
      "DELETE",
      BASE_URL + ENDPOINTS_API.DELETEGAME + `/${id}`,
      {
        Authorization: `Bearer ${token}`,
      },
      obj
    );
    return result?.data;
  } catch (error) {
    return error;
  }
};

export const handleDeleteEventById = async (id) => {
  const obj = { id };
  try {
    const result = await Instance(
      "DELETE",
      BASE_URL + ENDPOINTS_API.DELETEEVENT + `/${id}`,
      {
        Authorization: `Bearer ${token}`,
      },
      obj
    );
    return result?.data;
  } catch (error) {
    return error;
  }
};

export const handleCancelEventById = async ({ id }) => {
  const obj = {};

  try {
    const result = await Instance(
      "POST",
      BASE_URL + ENDPOINTS_API.CANCEL_EVENT + `/${id}`,
      {
        Authorization: `Bearer ${token}`,
      },
      obj
    );
    return result?.data;
  } catch (error) {
    return error;
  }
};

export const handleAddCouponByAdmin = async ({
  maxAmount,
  minAmount,
  matchCounts,
}) => {
  const obj = { maxAmount, minAmount, matchCounts };

  try {
    const result = await Instance(
      "POST",
      BASE_URL + ENDPOINTS_API.ADDCOUPON,
      {
        Authorization: `Bearer ${token}`,
      },
      obj
    );
    return result?.data;
  } catch (error) {
    return error;
  }
};

export const handleGetCouponByAdmin = async (currentPage) => {
  const obj = {};

  try {
    const result = await Instance(
      "GET",
      `${BASE_URL + ENDPOINTS_API.COUPONS}?page=${currentPage}`,
      {
        Authorization: `Bearer ${token}`,
      },
      obj
    );
    return result?.data;
  } catch (error) {
    return error;
  }
};

export const handleGetCouponByID = async ({ id }) => {
  const obj = {};

  try {
    const result = await Instance(
      "GET",
      BASE_URL + ENDPOINTS_API.GETCOUPONBYID + `/${id}`,
      {
        Authorization: `Bearer ${token}`,
      },
      obj
    );
    return result?.data;
  } catch (error) {
    return error;
  }
};

export const handleEditCouponByAdmin = async ({
  id,
  minAmount,
  maxAmount,
  matchCounts,
}) => {
  const obj = { minAmount, maxAmount, matchCounts };

  try {
    const result = await Instance(
      "PUT",
      BASE_URL + ENDPOINTS_API.EDITCOUPON + `/${id}`,
      {
        Authorization: `Bearer ${token}`,
      },
      obj
    );
    return result?.data;
  } catch (error) {
    return error;
  }
};

export const handleAddRules = async ({ name, rules, title, gameType }) => {
  const obj = { name, rules, title, gameType };

  try {
    const result = await Instance(
      "POST",
      BASE_URL + ENDPOINTS_API.ADDRULES,
      {
        Authorization: `Bearer ${token}`,
      },
      obj
    );
    console.log(result, "resulrmm");
    return result;
  } catch (error) {
    console.log(error, "asdfghertyui");
    return error;
  }
};

export const handleDeleteCoupon = async ({ id }) => {
  const obj = {};

  try {
    const result = await Instance(
      "DELETE",
      BASE_URL + ENDPOINTS_API.DELETECOUPON + `/${id}`,
      {
        Authorization: `Bearer ${token}`,
      },
      obj
    );
    return result?.data;
  } catch (error) {
    return error;
  }
};

export const handleGetRules = async (currentPage) => {
  const obj = {};

  try {
    const result = await Instance(
      "GET",
      `${BASE_URL + ENDPOINTS_API.GETRULES}?page=${currentPage}`,
      {
        Authorization: `Bearer ${token}`,
      },
      obj
    );
    return result?.data;
  } catch (error) {
    return error;
  }
};
export const handleGetRulesDelete = async ({ id }) => {
  try {
    console.log("hit1", id);
    const result = await Instance(
      "DELETE",
      `${BASE_URL}${ENDPOINTS_API.RULES_DELETE}/${id}`,
      {
        Authorization: `Bearer ${token}`,
      }
    );

    return result?.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const handleAddRoomId = async ({
  roomId,
  roomPassword,
  gameEventId,
}) => {
  const obj = { roomId, roomPassword, gameEventId };
  try {
    const result = await Instance(
      "PUT",
      BASE_URL + ENDPOINTS_API.ADDROOMID,
      {
        Authorization: `Bearer ${token}`,
      },
      obj
    );

    return result?.data || result?.response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const handleUserCouponData = async ({ id, currentPage }) => {
  const obj = {};
  try {
    const result = await Instance(
      "GET",
      BASE_URL + ENDPOINTS_API.USERCOUPONDATA + `/${id}?page=${currentPage}`,
      {
        Authorization: `Bearer ${token}`,
      },
      obj
    );

    return result?.data || result?.response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const handleUserTransaction = async ({ id, wallet, currentPage }) => {
  const obj = {
    wallet,
    userId: id,
  };
  try {
    const result = await Instance(
      "POST",
      BASE_URL + ENDPOINTS_API.USER_TRANSACTION + `?page=${currentPage}`,
      {
        Authorization: `Bearer ${token}`,
      },
      obj
    );

    return result?.data || result?.response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const handleGetTransactionList = async (currentPage, search) => {
  const obj = {};
  try {
    const result = await Instance(
      "GET",
      `${
        BASE_URL + ENDPOINTS_API.TRANSACTIONLIST
      }?page=${currentPage}&search=${search}`,
      {
        Authorization: `Bearer ${token}`,
      },
      obj
    );

    return result?.data || result?.response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const handleGetGametopPlayers = async (currentPage) => {
  const obj = {};
  try {
    const result = await Instance(
      "GET",
      `${BASE_URL + ENDPOINTS_API.GAMESTOPPLAYER}?page=${currentPage}`,
      {
        Authorization: `Bearer ${token}`,
      },
      obj
    );

    return result?.data || result?.response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const handleGetLudoTopPlayer = async (currentPage) => {
  const obj = {};
  try {
    const result = await Instance(
      "GET",
      `${BASE_URL + ENDPOINTS_API.LUDOTOPPLAYERS}?page=${currentPage}`,
      {
        Authorization: `Bearer ${token}`,
      },
      obj
    );

    return result?.data || result?.response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const handleGetUserNotification = async (currentPage) => {
  const obj = {};
  try {
    const result = await Instance(
      "GET",
      `${BASE_URL + ENDPOINTS_API.USERNOTIFICATION}?page=${currentPage}`,
      {
        Authorization: `Bearer ${token}`,
      },
      obj
    );

    return result?.data || result?.response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const handleGetAdminNotification = async () => {
  const obj = {};
  try {
    const result = await Instance(
      "GET",
      BASE_URL + ENDPOINTS_API.ADMINNOTIFICATION,
      {
        Authorization: `Bearer ${token}`,
      },
      obj
    );

    return result?.data || result?.response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const handleUpdateWinner = async ({ gameEventId, users }) => {
  const obj = { gameEventId, users };
  try {
    const result = await Instance(
      "POST",
      BASE_URL + ENDPOINTS_API.UPDATEWINNER,
      {
        Authorization: `Bearer ${token}`,
      },
      obj
    );
    return result?.data;
  } catch (error) {
    return error;
  }
};

export const handleGetChallengeById = async ({ id }) => {
  const obj = { id };
  try {
    const result = await Instance(
      "GET",
      BASE_URL_USER + ENDPOINTS_API.CHALLENGE + `/${id}`,
      {
        Authorization: `Bearer ${token}`,
      },
      obj
    );
    return result?.data;
  } catch (error) {
    return error;
  }
};

export const handleResendAmount = async ({ amount, userId }) => {
  const obj = { amount, userId };
  console.log(obj, "resend");
  try {
    const result = await Instance(
      "PUT",
      BASE_URL + ENDPOINTS_API.RETURNBALANCE,
      {
        Authorization: `Bearer ${token}`,
      },
      obj
    );
    return result?.data;
  } catch (error) {
    return error;
  }
};

export const handleTransferAmount = async ({ challengeId, userId }) => {
  const obj = { challengeId, userId };
  console.log(obj, "resend");
  try {
    const result = await Instance(
      "POST",
      BASE_URL + ENDPOINTS_API.TRANSFERBALANCE,
      {
        Authorization: `Bearer ${token}`,
      },
      obj
    );
    return result?.data;
  } catch (error) {
    return error;
  }
};

export const handleGetBlockedUerList = async () => {
  const obj = {};

  try {
    const result = await Instance(
      "GET",
      BASE_URL + ENDPOINTS_API.USERBLOCKEDLIST,
      {
        Authorization: `Bearer ${token}`,
      },
      obj
    );
    return result?.data;
  } catch (error) {
    return error;
  }
};

export const handleGetRefferalList = async () => {
  const obj = {};

  try {
    const result = await Instance(
      "GET",
      BASE_URL + ENDPOINTS_API.REFERRALLIST,
      {
        Authorization: `Bearer ${token}`,
      },
      obj
    );
    return result?.data;
  } catch (error) {
    return error;
  }
};

export const handleAllUserCouponData = async (currentPage) => {
  const obj = {};
  try {
    const result = await Instance(
      "GET",
      `${BASE_URL + ENDPOINTS_API.ALLCOUPONLIST}?page=${currentPage}`,
      {
        Authorization: `Bearer ${token}`,
      },
      obj
    );

    return result?.data || result?.response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const handleGetMatchRuleById = async ({ id }) => {
  const obj = {};
  try {
    const result = await Instance(
      "GET",
      BASE_URL + ENDPOINTS_API.EDITMATCHRULES + `/${id}`,
      {
        Authorization: `Bearer ${token}`,
      },
      obj
    );

    return result?.data || result?.response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const handleMatchWinningList = async ({ id, type }) => {
  const obj = {
    type,
    id,
  };
  try {
    const result = await Instance(
      "POST",
      BASE_URL_USER + ENDPOINTS_API.WINNING_HISTORY,
      {
        Authorization: `Bearer ${token}`,
      },
      obj
    );

    return result?.data || result?.response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const handleMatchWinningEsportList = async ({ id, type }) => {
  const obj = {
    type,
    id,
  };
  try {
    const result = await Instance(
      "POST",
      BASE_URL_USER + ENDPOINTS_API.WINNING_HISTORY,
      {
        Authorization: `Bearer ${token}`,
      },
      obj
    );

    return result?.data || result?.response;
  } catch (error) {
    console.log(error);
    return error;
  }
};
