import React, { useEffect, useState } from "react";
import { handleGetUserNotification } from "../networks/adminApi";
import Table from "../components/table";
import Pagination from "../components/pagination";
import { ENDPOINTS_API } from "../networks/apiConstants";
import { BASE_URL } from "../commonservice/commonservice";
import { callAPI } from "../networks/callApi";
import { useParams } from "react-router-dom";

const AnnouncementShowuser = () => {
  const [showUser, setShowUser] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const { id } = useParams();
  const handlenotificationlist = () => {
    callAPI(`${BASE_URL + ENDPOINTS_API.NOTIFICATION_LIST}/${id}`, "GET", {
      "Content-type": "application/json",
    })
      .then((res) => {
        console.log(res.data, "ppppppppppppppppppppppp");
        setShowUser(res.data?.users);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handlenotificationlist();
  }, []);

  const columns = [
    { Header: "S.No", accessor: "Sno" },
    { Header: "UserId", accessor: "userid" },
    { Header: "Name", accessor: "name" },
    { Header: "Email", accessor: "email" },
    { Header: "Mobile Number", accessor: "mobilenumber" },
  ];
  const tableHeading = {
    heading: "Notification User List",
  };

  const announcementData = [];

  showUser &&
    showUser?.forEach((item, index) => {
      announcementData.push({
        Sno: index + 1,
        userid: item._id,
        name: item.name,
        email: item.email,
        mobilenumber: item.mobile,
      });
    });

  return (
    <div>
      <div className="bg-darkBlue">
        <Table
          columns={columns}
          data={announcementData}
          titleData={tableHeading}
          headingSize={"text-[1rem]"}
        />
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

export default AnnouncementShowuser;
