import React, { useEffect, useState } from "react";
import InputComponent from "../components/inputComponent";
import TextAreaComponent from "../components/textAreaComponent";
import InputImage from "../components/inputImage";
import Button from "../components/button";
import { callAPI } from "../networks/callApi";
import { BASE_URL } from "../commonservice/commonservice";
import { ENDPOINTS_API } from "../networks/apiConstants";
import { toast } from "react-toastify";
import Table from "../components/table";
import Pagination from "../components/pagination";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const Announcement = () => {
  const [userList, setUserList] = useState([]);
  const [listDetail, setListDetail] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false); // State for Select All
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    title: "",
    message: "",
    image: null,
    userId: [],
  });

  const handleInputChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (file) => {
    const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
    if (file && validImageTypes.includes(file.type)) {
      setFormData((prevData) => ({
        ...prevData,
        image: file,
      }));
      toast.success("Image selected successfully");
    } else {
      toast.error(
        "Invalid file type. Please upload an image (JPEG, PNG, GIF)."
      );
    }
  };

  const handleNotification = () => {
    if (!formData.title.trim()) {
      toast.error("Title is required");
      return;
    }
    if (!formData.message.trim()) {
      toast.error("Message is required");
      return;
    }
    // if (formData.userId.length === 0) {
    //   toast.error("At least one User ID is required");
    //   return;
    // }
    if (!formData.image) {
      toast.error("Image is required");
      return;
    }
    const data = new FormData();
    data.append("title", formData.title);
    data.append("body", formData.message);
    data.append("image", formData.image);
    const userId = listDetail?.map((user) => user._id);

    selectedUserIds.forEach((item) => data.append("userIds", item));
    const startTime = Date.now();
    callAPI(
      BASE_URL + ENDPOINTS_API.SEND_NOTIFICATION,
      "POST",
      {
        headers: { "Content-type": "multipart/form-data" },
      },
      data
    )
      .then((res) => {
        const endTime = Date.now();
        console.log(startTime - endTime, "TIMETAKEN");
        toast.success("Notification Sent Successfully");
        navigate("/announcementList");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUserList = () => {
    callAPI(
      `${
        BASE_URL + ENDPOINTS_API.GETUSERDETAILS
      }?page=${currentPage}&search=${searchQuery}`,
      "GET",
      {
        "Content-type": "application/json",
      }
    )
      .then((res) => {
        console.log(res?.data?.users, "user");
        setListDetail(res.data?.users);
        setTotalPages(res?.totalPages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleUserList();
  }, [currentPage, searchQuery]);

  const filteredUsers = listDetail.filter(
    (user) =>
      String(user?._id).toLowerCase().includes(searchQuery.toLowerCase()) ||
      String(user?.name).toLowerCase().includes(searchQuery.toLowerCase()) ||
      String(user?.mobile).toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleCheckboxChange = (userId) => {
    setSelectedUserIds((prevSelected) =>
      prevSelected.includes(userId)
        ? prevSelected.filter((id) => id !== userId)
        : [...prevSelected, userId]
    );
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedUserIds([]);
    } else {
      const allUserIds = filteredUsers.map((user) => user?._id);
      setSelectedUserIds(allUserIds);
    }
    setSelectAll(!selectAll);
  };

  return (
    <div>
      <div className="ml-2 mt-4 w-[80%] bg-darkBlue px-4 pt-2 pb-10 rounded-[10px]">
        <div className="text-[1.5rem] text-white">ANNOUNCEMENT</div>
        <div className="text-white text-[.5rem]">Announcement for users</div>

        <div className="flex w-[30vw]">
          <div className="w-[30%] flex flex-col px-2 relative">
            <div
              className="cursor-pointer"
              onClick={() => setDropdownOpen(!dropdownOpen)}
            >
              <p
                style={{
                  color: "white",
                  border: "1px solid white",
                  width: "30vw",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                Select Users{" "}
                <span>
                  <MdOutlineArrowDropDown size={20} />
                </span>{" "}
              </p>
            </div>
            {dropdownOpen && (
              <div className="absolute top-8 left-0 right-0 bg-white z-10 max-h-64 overflow-y-auto w-[30vw]">
                <div
                  style={{ alignItems: "flex-end" }}
                  onClick={() => setDropdownOpen(false)}
                >
                  close
                </div>
                <div className="flex flex-col px-2 pb-2">
                  <input
                    type="text"
                    placeholder="Search users..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full p-2 border-b focus:outline-none border-gray-400 mb-2"
                  />
                  <div className="flex items-center mb-2">
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAllChange}
                    />
                    <label className="ml-2">Select All</label>
                  </div>
                </div>
                {filteredUsers.map((user) => (
                  <div
                    key={user._id}
                    className="flex py-1 px-2 w-full  justify-start items-center"
                  >
                    <input
                      type="checkbox"
                      checked={selectedUserIds.includes(user._id)}
                      onChange={() => handleCheckboxChange(user._id)}
                    />
                    <label className="ml-2 text-black">{user._id}</label>
                    <label className=" ml-6 text-black">{user.name}</label>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="mt-2 text-white">
          <p>Selected User IDs: {selectedUserIds.join(", ")}</p>
        </div>

        <InputComponent
          lable={"Title*"}
          placeholder={"Enter your title"}
          value={formData.title}
          onChange={(value) => handleInputChange("title", value)}
        />
        <TextAreaComponent
          lable={"Message*"}
          placeholder={"Enter your message"}
          value={formData.message}
          onChange={(value) => handleInputChange("message", value)}
        />
        <InputImage
          lable={"Image"}
          setFile={handleImageChange}
          setFilePreview={(preview) =>
            handleInputChange("imagePreview", preview)
          }
        />

        <Button onClick={handleNotification} lable={"Add Announcement"} />
      </div>

      <Table
        columns={[
          { Header: "S.No", accessor: "Sno" },
          { Header: "Name", accessor: "name" },
          { Header: "User Id", accessor: "userId" },
          { Header: "Email", accessor: "email" },
        ]}
        data={listDetail.map((item, index) => ({
          Sno: item.s_no,
          name: item.name || "Not Updated",
          userId: item._id,
          email: item.email,
        }))}
        titleData={{ heading: "User List" }}
        headingSize={"text-[1rem]"}
      />
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      />
    </div>
  );
};

export default Announcement;
