export const callAPI = async (url, method, requestHeaders, body) => {
  console.log(body, "body");

  const token = localStorage.getItem("token");
  const userToken = token;
  console.log(userToken, "Token Retrieved");

  requestHeaders["Authorization"] = `Bearer ${userToken}`;

  const fetchData = () =>
    new Promise((resolve, reject) => {
      fetch(url, {
        method: method,
        headers: requestHeaders,
        body: body,
      })
        .then((response) => {
          let statusCode = +response.status;

          console.log("statusCode", statusCode);
          console.log("NetworkConnection.js Raw Response -->", response);
          if (statusCode < 300) {
            resolve(response.json());
          } else {
            if (statusCode === 401 || statusCode === 403) {
              console.log("Unauthorized or Forbidden");
              localStorage.clear();
              window.location.href = "/login";
            }
            if (statusCode === 444) {
              window.location.href = "/dashboard";
            }
            reject(response.json());
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });

  // Check for internet connectivity
  const checkConnectivity = () => {
    if (!navigator.onLine) {
      showConnectivityPopup(); // Show popup when offline
      return false;
    }
    return true;
  };

  const showConnectivityPopup = () => {
    const existingPopup = document.getElementById("connectivity-popup");
    if (!existingPopup) {
      const popup = document.createElement("div");
      popup.id = "connectivity-popup";
      popup.style.position = "absolute";
      popup.style.top = "20px";
      popup.style.right = "20px";
      popup.style.padding = "10px 20px";
      popup.style.backgroundColor = "#f44336";
      popup.style.color = "#fff";
      popup.style.borderRadius = "5px";
      popup.style.boxShadow = "0px 4px 6px rgba(0, 0, 0, 0.1)";
      popup.style.zIndex = 9999;
      popup.innerHTML =
        "Internet connectivity lost.........";
      document.body.appendChild(popup);
    }
  };

  const hideConnectivityPopup = () => {
    const popup = document.getElementById("connectivity-popup");
    if (popup) {
      popup.remove();
    }
  };

  // Retry the API call until connectivity is restored, then refresh the page
  const retryUntilConnected = () => {
    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        if (checkConnectivity()) {
          clearInterval(interval);
          hideConnectivityPopup(); // Hide popup when back online
          window.location.reload(); // Refresh the page when back online
        }
      }, 3000); // Check connectivity every 3 seconds
    });
  };

  // Check if online first, otherwise retry
  if (!checkConnectivity()) {
    return retryUntilConnected();
  } else {
    return fetchData();
  }
};
