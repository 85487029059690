/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import {
  handleGameEventById,
  handleGetParticipants,
  handleUpdateWinner,
} from "../../networks/adminApi";
import Table from "../../components/table";
import InputComponent from "../../components/inputComponent";
import SelectComponet from "../../components/selectComponet";
import Button from "../../components/button";
import { toast } from "react-toastify";
import { FaEdit, FaEye } from "react-icons/fa";
import { SocketProvider } from "../../socket/SocketsContextProvider";

const UserDetails = ({ id }) => {
  return <Link to={`/getParticipantDetials/${id}`}>user Details</Link>;
};

const NameComponent = ({ data }) => {
  return (
    <div>
      <div>
        UserName:{" "}
        {data?.username?.length < 25
          ? data?.username
          : data?.username.slice(0, 25) + "..."}
      </div>
      <div>
        UserId:{" "}
        {data?.userId?.length > 25
          ? data.userId.slice(0, 25) + "..."
          : data.userId}
      </div>
    </div>
  );
};

const TotalKillComponent = ({ id, value, onChange, perKill }) => {
  return (
    <div>
      <InputComponent
        value={value}
        onChange={(e) => onChange(id, "totalKill", e)}
        type={"number"}
      />
      {/* <div>x{perKill}</div> */}
    </div>
  );
};

const PoolPrizeComponent = ({ id, value, onChange, kills, perKillAmount }) => {
  console.log(value, "Value of pool prize");
  return (
    <div>
      <InputComponent
        value={value}
        onChange={(e) => onChange(id, "poolPrize", e)}
        type={"number"}
      />
    </div>
  );
};

const WinningAmount = ({
  id,
  value,
  onChange,
  kills,
  perKillAmount,
  prizeMoneyAmount,
  position,
  poolPrize,
}) => {
  return (
    <div>
      <InputComponent
        value={kills * perKillAmount + parseInt(poolPrize) || value}
        onChange={(e) => onChange(id, "winningAmount", e)}
        type={"number"}
        readOnly={true}
      />
    </div>
  );
};

const PositionComponent = ({ id, value, ranks, onSelect }) => {
  return (
    <div>
      <SelectComponet
        value={value}
        onSelect={(e) => onSelect(id, "position", e)}
        data={ranks}
        width={"w-[120px]"}
      />
    </div>
  );
};

const GetDescription = () => {
  const [eventData, setEventData] = useState({});
  const [participantsData, setParticipantsData] = useState();
  const { id } = useParams();
  const [inputs, setInputs] = useState({});
  const [ranks, setRanks] = useState([]);
  const [totalPrice, setTotalPrice] = useState("");
  const [perKillAmount, setPerKillAmount] = useState("");
  const [prizeMoneyAmount, setPrizeMoneyAmount] = useState("");
  const [searchTerm, setSearchTerm] = useState(""); // Added search term state
  const { socketRef } = useContext(SocketProvider);

  console.log({ inputs });
  useEffect(() => {
    const inputsLength = Object.keys(inputs).length;
    const newRanks = generateRanks(inputsLength);
    setRanks(newRanks);
  }, [inputs]);

  const generateRanks = (length) => {
    const rankTitles = ["Winner", "2nd Runner-up", "3rd Runner-up"];
    const newRanks = [];

    for (let i = 0; i < length; i++) {
      if (i < rankTitles.length) {
        newRanks.push(rankTitles[i]);
      } else {
        newRanks.push(`${i + 1}th Rank`);
      }
    }

    return newRanks;
  };

  const handleInputChange = (_id, item, value, items) => {
    if (value < 0) {
      toast.error("value Cannot be negative");
      return;
    }
    setInputs((prev) => ({
      ...prev,
      [_id]: {
        ...(prev[_id] || {}),
        [item]: value || "",
      },
    }));
    console.log(item, "item", value);
    // const handleAdminPrize = () => {
    //   callAPI(
    //     BASE_URL + ENDPOINTS_API.ADMIN_PRIZE,
    //     "POST",
    //     {
    //       "Content-type": "application/json",
    //     },
    //     JSON.stringify({
    //       userId: items._id,
    //       kills: value,
    //       // positions: inputs[items._id]["position"],
    //       gameEventId: items?.gameEventId,
    //     })
    //   )
    //     .then((res) => {
    //       console.log(res, "kkkkjhdhudwhu");
    //       setTotalPrice(res.totalPrize);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // };
    // handleAdminPrize();
  };

  const handleOptionChange = (_id, item, value) => {
    setInputs((prev) => ({
      ...prev,
      [_id]: {
        ...(prev[_id] || {}),
        [item]: value || "",
      },
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await handleGameEventById({ id });
      const res2 = await handleGetParticipants({ id });

      if (res?.data) {
        setPrizeMoneyAmount(res?.data?.prize);
        setPerKillAmount(res?.data?.perKill);
        setEventData(res?.data);
      }

      if (res2?.data) {
        // Initialize state for each participant's input fields
        const initialInputs =
          res2.data.participants.reduce((acc, participant) => {
            acc[participant._id] = {
              totalKill: participant.kills || "",
              poolPrize: 0,
              position: participant.position || "",
              winningAmount: participant.winningAmount || "",
              id: participant.userId,
            };
            return acc;
          }, {}) || {};
        setInputs(initialInputs);
        setParticipantsData(res2?.data?.participants);
      }
    };
    fetchData();
  }, [id]);

  const filteredPrticipants = participantsData?.filter((user) => {
    return (
      String(user?.username)
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      String(user?.email)?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      String(user?.userId)?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      String(user?.mobile)?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const columns = [
    // { Header: "Game Name", accessor: "gameName" },
    { Header: "Title", accessor: "title" },
    { Header: "Event Type", accessor: "eventType" },
    { Header: "Entry Fee", accessor: "entryFee" },
    // { Header: "Image Preview", accessor: "imgPreview" },
    { Header: "Date", accessor: "date" },
    { Header: "Map", accessor: "map" },
    { Header: "Per Kill", accessor: "perKill" },
    { Header: "Prize", accessor: "prize" },
    { Header: "Version", accessor: "version" },
  ];

  const eventDescription = [];

  eventDescription.push({
    id: eventData.id,
    title: eventData.title,
    eventType: eventData.eventType,
    entryFee: eventData.entryFee || "free",

    // imgPreview: (
    //   <div className="flex justify-center">
    //     <img src={eventData.image} className="h-10 w-10" />
    //   </div>
    // ),
    date: eventData.date,
    map: eventData.map,
    perKill: eventData.perKill,
    prize: eventData.prize,
    version: eventData.version,
  });

  const gameEventHeading = {
    heading: "Game Details",
  };

  const ParticipantHeader = [
    { Header: "S.No", accessor: "s_no" },
    { Header: "Players", accessor: "name" },
    { Header: "Joined By", accessor: "joinedBy" },
    { Header: "Total Kill", accessor: "totalKill" },
    { Header: "Pool Prize", accessor: "poolPrize" },
    { Header: "Position", accessor: "position" },
    { Header: "Winning Amount", accessor: "winningAmount" },
    // { Header: "Action", accessor: "action" },
  ];

  console.log({ inputs });

  const participantList = [];
  filteredPrticipants &&
    filteredPrticipants?.forEach((items) =>
      participantList.push({
        id: items._id,
        s_no: items.s_no,
        name: <NameComponent data={items} />,
        joinedBy: items?.joinedByUserId?.name,
        totalKill: (
          <TotalKillComponent
            id={items._id}
            value={inputs[items._id]?.totalKill || ""}
            onChange={(e, item, value) =>
              handleInputChange(e, item, value, items)
            }
            perKill={eventData.perKill}
          />
        ),
        poolPrize: (
          <PoolPrizeComponent
            id={items._id}
            value={inputs[items._id]?.poolPrize}
            onChange={(e, item, value) => handleInputChange(e, item, value)}
            kills={inputs[items._id]?.totalKill || ""}
            perKillAmount={perKillAmount}
          />
        ),
        position: (
          <PositionComponent
            id={items._id}
            value={inputs[items._id]?.position || ""}
            ranks={ranks}
            onSelect={handleOptionChange}
          />
        ),
        winningAmount: (
          <WinningAmount
            id={items._id}
            value={inputs[items._id]?.winningAmount || ""}
            onChange={(e, item, value) => handleInputChange(e, item, value)}
            kills={inputs[items._id]?.totalKill || ""}
            perKillAmount={perKillAmount}
            prizeMoneyAmount={prizeMoneyAmount}
            position={inputs[items._id]?.position || ""}
            poolPrize={inputs[items._id]?.poolPrize || ""}
          />
        ),
        // action: (
        //   <div className="flex justify-center">
        //     <FaEdit />
        //   </div>
        // ),

        // viewDocument: (
        //   <div className="flex justify-center cursor-pointer">
        //     <FaEye className="text-blue-500 hover:text-blue-700" />
        //   </div>
        // ),
        // userDetails: <UserDetails id={items._id} />,
      })
    );

  const participantsHeading = {
    heading: "All Participants",
  };

  const validateInputs = () => {
    for (let key in inputs) {
      const { totalKill, poolPrize, winningAmount } = inputs[key];
      if (
        parseInt(totalKill) <= 0 ||
        parseInt(poolPrize) <= 0 ||
        parseInt(winningAmount) <= 0
      ) {
        return false;
      }
    }
    return true;
  };

  const handleSendPrize = async () => {
    const users = Object.entries(inputs).map(([key, value]) => ({
      userId: key,
      kills: parseInt(value.totalKill),
      position: value.position,
      id: value.id,
      winningAmount:
        parseInt(value.totalKill * perKillAmount) + parseInt(value.poolPrize),
    }));
    if (participantsData.length > 0) {
      const res = await handleUpdateWinner({
        gameEventId: eventData._id,
        users,
      });

      if (res?.success) {
        if (socketRef.current) {
          const userIds = users.map((user) => user.userId);
          socketRef.current.emit("update-balance", { id: userIds });
        }
        toast.success(res?.message);
      } else {
        toast.error(res?.message);
      }
    } else {
      toast.warn("Please wait for players to join the match");
    }
  };

  return (
    <div className="mx-4">
      <Table
        columns={columns}
        data={eventDescription}
        titleData={gameEventHeading}
        headingSize={"text-[1rem]"}
      />

      <div>
        {/* <Button lable={"Send Prize"} onClick={() => handleSendPrize()} /> */}
        <div className="search-bar" style={{ marginTop: "1rem" }}>
          <input
            type="text"
            placeholder="Search here....."
            value={searchTerm}
            onChange={handleSearch}
            style={{
              padding: "5px",
              width: "100%",
              maxWidth: "300px",
              borderRadius: "4px",
              border: "1px solid",
              marginLeft: "5px",
            }}
          />
        </div>
        <Table
          columns={ParticipantHeader}
          data={participantList}
          titleData={participantsHeading}
          headingSize={"text-[1rem]"}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1rem",
          }}
        >
          <Button lable={"Send Prize"} onClick={() => handleSendPrize()} />
        </div>
      </div>
    </div>
  );
};

export default GetDescription;
